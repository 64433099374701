<template>
    <div class="con-wrap">
        <CarrotTitle :title="add.info.ename+'('+add.info.kname+') MCG 등록'">
            - 종합 Comment, Guide & Check Point, 역량리뷰 필수 입력사항입니다.<br>
            - 팀원의 평소 업무 중 행동을 떠올리며 종합 Comment, Guide & Check Point, 역량리뷰를 작성해 주세요.<br>
            - 월 1회, 연 최소4번~ 최대12번 작성 가능합니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="150">
                            <col width="*">
                            <col width="*">
                            <col width="110">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>본부</th>
                                <th>팀</th>
                                <th>팀원</th>
                                <th>직급</th>
                                <th>입사일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ add.info.office_name }}</td>
                                <td>{{ add.info.team_name }}</td>
                                <td>{{ add.info.ename }}({{ add.info.kname }})</td>
                                <td>{{ add.info.position_name }}</td>
                                <td>{{ add.info.joindate }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="mt-50 mb-20">
                        <select name="syear" id="syear" class="w-100px" v-model="add.syear" @change="add.makeSearchMonths">
                            <option :value="v" v-for="(v, k) in add.search_years" :key="k">{{ v }}</option>
                        </select>
                        <select name="smonth" id="smonth" class="w-100px ml-5" v-model="add.smonth" @change="add.doSearch(0)">
                            <option value="">선택</option>
                            <option :value="v" v-for="(v, k) in add.search_months" :key="k">{{ v }}</option>
                        </select>
                        <div class="clear"></div>
                    </div>

                    <table class="table-row">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>주요 업무 목표</th>
                                <td>
                                    <textarea class="w-100per h-100px" placeholder="(해당 월 개인 업무/KPI 목표)" v-model="add.biz_goal"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>실행 사항 점검</th>
                                <td>
                                    <textarea class="w-100per h-100px" placeholder="(전달 Action Plan 실행 사항 점검)" v-model="add.pastplan"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>주요내용</th>
                                <td>
                                    <textarea class="w-100per h-100px" placeholder="(면담시 진행된 내용)" v-model="add.mainplan"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>Action Plan</th>
                                <td>
                                    <textarea class="w-100per h-100px" placeholder="(다음달 실행계획)" v-model="add.actionplan"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>기타 고려사항</th>
                                <td>
                                    <textarea class="w-100per h-100px" placeholder="(특이사항 / 개인 신상관련 등)" v-model="add.etc_memo"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <button class="btn-default float-left mt-30" @click="add.goList">목록</button>

                    <div v-if="add.state!='Y'">
                        <button class="btn-default float-right mt-30 ml-20" @click="add.doSubmit">제출</button>
                        <button class="btn-default float-right mt-30" @click="add.tempSave">임시저장</button>
                    </div>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'


export default {
    layout:"myPIMS",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast  = useToast();

        const add = reactive({
            search_years  : [],
            search_months : [],

            idx    : 0,
            idx_hq : 0,
            syear  : "",
            smonth : "",
            info   : {},
            biz_goal   : "",
            pastplan   : "",
            mainplan   : "",
            actionplan : "",
            etc_memo   : "",
            state      : "",

            tempSave : () => {
                let params = {
                    idx         : add.idx,
                    idx_hq      : add.idx_hq,
                    syear       : add.syear,
                    smonth      : add.smonth,
                    biz_goal    : add.biz_goal,
                    pastplan    : add.pastplan,
                    mainplan    : add.mainplan,
                    actionplan  : add.actionplan,
                    etc_memo    : add.etc_memo,
                    state       : "T"
                };

                axios.post('/rest/mypims/setMCG', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("임시저장 하였습니다.");
                    } else if( res.data.err == 401 ){
                        toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx         : add.idx,
                    idx_hq      : add.idx_hq,
                    syear       : add.syear,
                    smonth      : add.smonth,
                    biz_goal    : add.biz_goal,
                    pastplan    : add.pastplan,
                    mainplan    : add.mainplan,
                    actionplan  : add.actionplan,
                    etc_memo    : add.etc_memo,
                    state       : "Y"
                };

                if( !params.biz_goal ){
                    toast.error('주요 업무 목표를 입력하세요.');
                    return;
                }
                if( !params.pastplan ){
                    toast.error('실행 사항 점검을 입력하세요.');
                    return;
                }
                if( !params.mainplan ){
                    toast.error('주요내용을 입력하세요.');
                    return;
                }
                if( !params.actionplan ){
                    toast.error('Action Plan을 입력하세요.');
                    return;
                }
                if( !params.etc_memo ){
                    toast.error('기타 고려사항을 입력하세요.');
                    return;
                }

                Swal.fire({
                    title : 'MCG 등록',
                    text  : '제출 후에는 수정이 불가합니다. 제출하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                    axios.post('/rest/mypims/setMCG', params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success('제출하였습니다.');
                                router.back(-1);
                            } else {
                                if( res.data.err == 401 ){
                                    toast.error(res.data.err_msg);
                                } else {
                                    if(res.data.err_msg) toast.error(res.data.err_msg);
                                }
                            }
                        });
                    }
                })
            },

            doSearch : (idx) => {
                let params = {
                    idx    : idx,
                    idx_hq : add.idx_hq,
                    syear  : add.syear,
                    smonth : add.smonth
                };
                axios.get("/rest/mypims/getMCGInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        add.info = res.data.info;

                        add.idx = res.data.idx;

                        add.syear  = res.data.syear;

                        if( add.idx > 0 ){
                            router.replace(add.idx);
                            if( res.data.biz_goal   ) add.biz_goal   = res.data.biz_goal;
                            if( res.data.pastplan   ) add.pastplan   = res.data.pastplan;
                            if( res.data.mainplan   ) add.mainplan   = res.data.mainplan;
                            if( res.data.actionplan ) add.actionplan = res.data.actionplan;
                            if( res.data.etc_memo   ) add.etc_memo   = res.data.etc_memo;

                            add.state = res.data.state;
                        } else {
                            add.biz_goal   = '';
                            add.pastplan   = '';
                            add.mainplan   = '';
                            add.actionplan = '';
                            add.etc_memo   = '';
                            add.state      = '';
                        }

                        add.makeSearchYears();
                        add.makeSearchMonths();

                        add.smonth = res.data.smonth;
                    } else {
                        toast.error('잘못된 접근입니다.');
                        router.push({
                            name : "myPIMS-MCRMCGView-idx",
                            params : { idx:add.idx_hq }
                        });
                    }
                });
            },

            goList : () => {
                Swal.fire({
                    title : 'MCG 등록',
                    text  : '입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        router.go(-1);
                        // router.push({
                        //     name : "myPIMS-MCRMCGView-idx",
                        //     params : { idx:add.idx_hq }
                        // });
                    }
                });
            },
            
            makeSearchYears: () => {
                let ey = add.info.joindate.substr(0, 4);
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=ey; i--){
                    add.search_years.push(i);
                }
            },

            makeSearchMonths: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                add.search_months = [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ];
                if( Number(add.syear) >= cy ){
                    add.search_months.splice(cd.getMonth()+1, 12-cd.getMonth());
                }
                add.smonth = "";
            }
        });

        onMounted(() => {
            // Mounted
            add.idx    = route.params.idx?route.params.idx:"0";

            if( add.idx == 0 ){
                let td = new Date();
                add.syear  = td.getFullYear();
                add.smonth = td.getMonth()>8?(td.getMonth()+1):'0'+(td.getMonth()+1);
            }

            let ss = sessionStorage.getItem("MCRMCGView");
            if( ss ){
                ss = JSON.parse(ss);
                add.idx_hq = ss.idx;
            }

            add.doSearch(add.idx);
        });

        return {add};
    }
}
</script>

<style lang="scss" scoped>
</style>